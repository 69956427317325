import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Image from "react-bootstrap/Image";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import iconToUse from "./imgs/bars.svg";
import logo from "./imgs/logo-coflow.png";
import { coFounders, teamRow1, teamRow2 } from "./constants";
import ScrollspyNav from "react-scrollspy-nav";

function App() {
  return (
    <>
      <ScrollspyNav
        scrollTargetIds={["start", "what", "how", "team"]}
        activeNavClass="active"
        scrollDuration="10"
        headerBackground="true"
        offset={-56}
      >
        <Navbar expand="lg" fixed="top">
          <Container fluid>
            <Navbar.Brand href="#start">
              <img src={logo} alt="logo" height={28} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav">
              <img src={iconToUse} alt="Icono para abrir menu" />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link href="#start">Inicio</Nav.Link>
                <Nav.Link href="#what">Coflow</Nav.Link>
                <Nav.Link href="#how">Cómo funciona</Nav.Link>
                <Nav.Link href="#team">Equipo</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </ScrollspyNav>
      <Container fluid data-bs-spy="scroll">
        <Row className="startSpace" id="start" />
        <Row className="coflowSpace" id="what">
          <Col md lg={{ span: 8, offset: 2 }}>
            <div className="d-flex flex-column justify-content-center h-100">
              <h5>
                Plataforma tecnológica enfocada en resolver problemas reales de
                las empresa basada en la generación, uso y aprendizaje
                progresivo de conocimiento obtenido desde sus distintas fuentes
                de datos y/o información.
              </h5>
            </div>
          </Col>
        </Row>
        <Row className="howItWorkSpace" id="how">
          <Col md className="columnText">
            <div className="d-flex flex-column justify-content-center h-100">
              <h3>CÓMO FUNCIONA</h3>
              <p>Sistema colaborativo y de aprendizaje automático que:</p>
              <ol>
                <li>
                  Captura de forma automática los datos e información
                  (documentos, conversaciones, estados de animo, reuniones,
                  etc.) que van generando los usuarios en su día a día para
                  almacenarlos en bases de bigdata y vectores con el fin generar
                  conocimiento el cual puede ser aprovechado posteriormente en
                  la toma de decisiones.
                </li>
                <li>
                  Provee herramientas y proporciona información de y para la
                  organización:
                  <ul>
                    <li>
                      Incorpora un workflow global de trabajo personalizable en
                      distintos niveles.
                    </li>
                    <li>
                      Buscador inteligente de datos, información, preguntas
                      frecuentes, archivos el cual incorpora además búsqueda
                      semántica.
                    </li>
                    <li>
                      Asistente de conversaciones de compromisos, evaluación y
                      decisiones.
                    </li>
                    <li>
                      Dashboard:
                      <ul>
                        <li>Asignaciones y compromisos.</li>
                        <li>Estado de la organización.</li>
                        <li>
                          Indicadores (OKR, salud de la empresa, capacidad real
                          vs utilizada, entre otros)
                        </li>
                        <li>Anticipación de Quiebres</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                <b>
                  {" "}
                  Valor: Incremento de bienestar, cognición y resiliencia de la
                  organización con consecuente aumento de productividad
                </b>
              </p>
            </div>
          </Col>
          <Col md className="howWorks" />
        </Row>
        <Row className="teamSpace" id="team">
          <Col>
            <Row>
              <Col>
                <h3 className="text-center">EQUIPO</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardGroup>
                  {coFounders.map(function (data) {
                    return (
                      <Card key={data.name}>
                        <Image
                          src={data.photo}
                          alt={data.photoAlt}
                          roundedCircle
                          width={150}
                          height={150}
                        />
                        <Card.Body className="text-center">
                          <Card.Title>{data.name}</Card.Title>
                          <Card.Text>{data.info}</Card.Text>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </CardGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardGroup className="teamDivision">
                  {teamRow1.map(function (data) {
                    return (
                      <Card key={data.name}>
                        <Image
                          src={data.photo}
                          alt={data.photoAlt}
                          roundedCircle
                          width={150}
                          height={150}
                        />
                        <Card.Body className="text-center">
                          <Card.Title>{data.name}</Card.Title>
                          <Card.Text>{data.info}</Card.Text>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </CardGroup>
                <CardGroup className="teamDivision">
                  {teamRow2.map(function (data) {
                    return (
                      <Card key={data.name}>
                        <Image
                          src={data.photo}
                          alt={data.photoAlt}
                          roundedCircle
                          width={150}
                          height={150}
                        />
                        <Card.Body className="text-center">
                          <Card.Title>{data.name}</Card.Title>
                          <Card.Text>{data.info}</Card.Text>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </CardGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="footer">
          <Col className="d-flex align-items-center justify-content-center">
            <p>© 2024 Coflow. Todos los derechos reservados.</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
