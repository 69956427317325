import RRTphoto from "./imgs/RRT2.jpg";
import ACSphoto from "./imgs/ACS.jpg";
import PAPphoto from "./imgs/PAP.jpg";
import GRZphoto from "./imgs/GRZ.jpg";
import VRRphoto from "./imgs/VRR.jpg";
import JOGphoto from "./imgs/JOG.jpg";
import PZMphoto from "./imgs/PZM.jpg";
import NSMphoto from "./imgs/NSM.jpg";
import TNLphoto from "./imgs/TNL.jpg";

export const coFounders = [
  {
    photo: RRTphoto,
    photoAlt: "foto Rodrigo Riquelme",
    name: "Rodrigo A. Riquelme Tapia",
    info: "Gerente de recursos y reservas",
  },
  {
    photo: ACSphoto,
    photoAlt: "foto Alejandro Cáceres",
    name: "Alejandro M. Cáceres Saavedra",
    info: "Geólogo consultor principal",
  },
  {
    photo: PAPphoto,
    photoAlt: "foto Pamela Arellano",
    name: "Pamela A. Arellano Pérez",
    info: "Gerenta general",
  },
];

export const teamRow1 = [
  {
    photo: GRZphoto,
    photoAlt: "foto Gonzalo Rojas",
    name: "Gonzalo A. Rojas Zúñiga",
    info: "Director de finanzas",
  },
  {
    photo: VRRphoto,
    photoAlt: "foto Victor Riquelme",
    name: "Victor I. Riquelme Rojas",
    info: "Director de tecnología",
  },
  {
    photo: JOGphoto,
    photoAlt: "foto Javier Ortiz",
    name: "Javier E. Ortiz Guzmán",
    info: "Ingeniero de datos senior",
  },
];

export const teamRow2 = [
  {
    photo: PZMphoto,
    photoAlt: "foto Patricio Zúñiga",
    name: "Patricio A. Zúñiga Muñoz",
    info: "Desarrollador full stack",
  },
  {
    photo: NSMphoto,
    photoAlt: "foto Natalia Sepúlveda",
    name: "Natalia E. Sepúlveda Mora",
    info: "Desarrolladora full stack",
  },
  {
    photo: TNLphoto,
    photoAlt: "foto Tamara Namuncura",
    name: "Tamara P. Namuncura Luque",
    info: "Senior Front-end Developer",
  },
];
